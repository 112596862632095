export const getCompanyId = () => {
	let companyId = "";
	if (typeof window !== "undefined") {
		// TODO: Do this in Gatsby
		const match = window.location.pathname.match(
			/admin\/dealer-management\/(\d+)\//,
		);
		companyId = match?.[1] || "";
	}
	return companyId;
};
