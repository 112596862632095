import React from "react";
import MuiSelect from "@material-ui/core/Select";
import type { Select as SelectType, SelectProps } from "./types";

const Select: SelectType = function Select(props) {
	return <MuiSelect {...(props as any)} />;
};

export * from "@material-ui/core/Select";
export type { SelectProps };
export default Select;
