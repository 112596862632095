import React, { useState } from "react";
import { Box, FormControl, makeStyles } from "@material-ui/core";
import { kebabCase } from "lodash";
import { useUpdateCta } from "@api/ctas";
import { useFeedbackContext } from "~/components/Feedback/context";
import { Input } from "~/components/Input";
import { PrimaryButton } from "~/components/Button";
import { Typography } from "~/components/Typography";
import { isValidJson } from "..";

const useStyles = makeStyles(() => {
	return {
		form: {
            display: "flex",
            flexDirection: "column",
            margin: "0 15px",
		},
        inputContainer: {
            margin: "10px 0",
        },
        buttonContainer: {
			display: "flex",
			justifyContent: "flex-end",
			marginTop: "15px",
		},
        cancelButton: {
            background: "#C3002E",
            marginLeft: "15px",
        },
	};
});

interface IAddThemeForm {
    ctaId: string;
    code: string;
    curTemplateDefs: Record<string, any>;
    curThemeDefs: Record<string, any>;
    themeList: Maybe<Maybe<string>[]>;
    setShowAddThemeButton: React.Dispatch<React.SetStateAction<boolean>>;
    closeModal: () => void;
    save: () => void;
}

const AddThemeForm: React.FC<IAddThemeForm> = ({
    ctaId,
    code,
    curTemplateDefs,
    curThemeDefs,
    themeList,
    setShowAddThemeButton,
    closeModal,
    save,
}) => {
    const { updateCta } = useUpdateCta();
	const { handleOpenFeedback } = useFeedbackContext();
    const [themeName, setThemeName] = useState("");
    const [ error, setError] = useState("");
    const styles = useStyles();

    const handleAddTheme = () => {
        const formattedThemeName = kebabCase(themeName.toLowerCase());
        if (!formattedThemeName) {
            setError("Theme Name cannot be empty");
            return;
        }
        if (themeList?.includes(formattedThemeName)) {
            setError("Theme Name already exists");
            return;
        }

		const codeObj = isValidJson(code) && JSON.parse(code);
        const updateCtaInput = {
            id: ctaId,
            patch: {
                templateDef: {
                    defs: {
                        ...curTemplateDefs,
                        [formattedThemeName]: {
                            ...codeObj.templateDef,
                        },
                    },
                    version: "1.0.0",
                },
                themeDef: {
                    defs: {
                        ...curThemeDefs,
                        [formattedThemeName]: {
                            ...codeObj.themeDef,
                        },
                    },
                    version: "1.0.0",
                },
            },
        };

        updateCta({ variables: { input: updateCtaInput }});
        handleOpenFeedback({
            message: "New Theme has been Added.",
            severity: "success",
        });
        
        setTimeout(() => {
            save();
            setShowAddThemeButton(false);
        }, 500);

        closeModal();
    }

    return (
        <Box className={styles.form}>
            <Typography style={{ width: "300px" }}>
                Enter a new name for this theme:
            </Typography>
            <FormControl fullWidth className={styles.inputContainer}>
                <Input
                    label="Theme Name"
                    value={themeName}
                    placeholder="New Theme Name"
                    onChange={(e) => {
                        setError("");
                        const val = e.target.value;
                        setThemeName(val);
                    }}
                    error={error}
                />
            </FormControl>

            <Box className={styles.buttonContainer}>
                <PrimaryButton
                    onClick={handleAddTheme}
                >
                    Add Theme
                </PrimaryButton>
                <PrimaryButton
                    onClick={closeModal}
                    className={styles.cancelButton}
                >
                    Cancel
                </PrimaryButton>
            </Box>
        </Box>
    );
};

export default AddThemeForm;