import React from "react";

import { Typography } from "~/components/Typography";

import { ProductType } from "@api/products";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

interface CTAProps {
	product?: ProductType;
	type?: string;
	company?: string;
	label?: string;
	maxWidth?: string;
	height?: string;
}

const getCTAHeight = (product: ProductType) => {
	if (product === "MSA_TOOL") {
		return "550px";
	}
	return "350px";
};

const useStyles = makeStyles<Theme, CTAProps>(() => {
	return {
		iframe: (props) => ({
			width: "100%",
			height: props.height || getCTAHeight(props?.product!),
			border: 0,
			maxWidth: props.maxWidth,
		}),
		ctaWrapper: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			margin: "12px 0",
		},
		label: {
			marginLeft: 10,
		},
	};
});

export const CTA = (ctaProps: CTAProps) => {
	const { product, type, company, label } = ctaProps;
	const styles = useStyles(ctaProps);
	if (!product || !type || !company) {
		return null;
	}
	return (
		<Box className={styles.ctaWrapper}>
			{label && (
				<Typography className={styles.label} variant="caption">
					{label}
				</Typography>
			)}
			<iframe
				className={styles.iframe}
				src={`/cta-preview/index.html?avaPreview=1&product=${product}&type=${type}&company=${company}`}
			/>
		</Box>
	);
};
